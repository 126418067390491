import React from 'react';
import './index.css';
import './App.css';
import { ReactComponent as Logo } from './static/svg/3gradient-logo.svg';
import bonk from './static/images/bonk.png';
import moonwalk from './static/images/moonwalk.png';
import golfn from './static/images/golfn.png';
import quekz from './static/images/quekz.png';
import swolsol from './static/images/swolsol.png';
import x from './static/images/x.png';

function App() {
  return (
    <div>
      <header className="App-header">
        <div className='flex center pb-16'>
          <Logo></Logo>
        </div>
        <div className='flex flex-col text-center gap-12'>

          <div>
            {/* <h2>Our Clients</h2> */}
            <div className='flex flex-row gap-6'>
          
              <div><a href="https://bonkcoin.com"><img src={bonk} width="192px" /></a></div>
              <div><a href="https://moonwalk.fit"><img src={moonwalk} width="192px" /></a></div>
              {/* <div><a href="https://www.tensor.trade/trade/quekz"><img src={quekz} width="192px" /></a></div> */}
              <div><a href="https://www.golfn.com/"><img src={golfn} width="192px" /></a></div>
              
            </div>
          </div>

          <h1 className="bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500">
            degenerate consulting + devs <br/>contact <a className='text-white' href='https://t.me/swolsol'>@swolsol</a>
          </h1>
          
          <div className='flex flex-row items-center justify-center gap-6'>
            <a className='text-white' href='https://t.me/swolsol'><img src={swolsol} className="rounded-full" width="70px" /></a>
          </div>

          <div className='flex justify-center'>
            <a href="https://twitter.com/spid3r_dev"><img src={x} width="20px" className='rounded' /></a>
          </div>

        </div>
        
        <div>
        </div>
      </header>
    </div>
  );
}

export default App;
